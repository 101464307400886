import {EuiDescribedFormGroup, EuiText, EuiTitle} from "@elastic/eui";
import React from 'react';
import {useTranslation} from "react-i18next";
import StringToId from "../../code/helpers/StringToId";

type Props = {
    children: React.ReactNode
}

const ImporterData = ({children}: Props) => {
    const {t} = useTranslation(['main']);
    return (
        <EuiDescribedFormGroup
            id={StringToId(t("main:importer.title"))}
            title={<EuiTitle size={"s"}><h1>{t("main:importer.title", {defaultValue: "Importer Information"})}</h1>
            </EuiTitle>}
            description={
                <EuiText>{t("main:importer.description", {defaultValue: "Enter the importer information"})}</EuiText>
            }>
            {children}
        </EuiDescribedFormGroup>
    );
};

export default ImporterData;
