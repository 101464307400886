import React from 'react';
import {useController, UseControllerProps} from "react-hook-form";
import {EuiRadioGroup} from "@elastic/eui";
import {EuiRadioGroupProps} from "@elastic/eui/src/components/form/radio/radio_group";

type Props<T, > = UseControllerProps<T> & Omit<EuiRadioGroupProps, "onChange">;

const RadioGroup = <T, >(props: Props<T>) => {
    const {field: {name, onBlur, onChange, value}} = useController<T>(props);
    return (
        <EuiRadioGroup
            onBlur={onBlur}
            {...props as EuiRadioGroupProps}
            name={name}
            onChange={onChange}
            idSelected={value}
        />
    );
};

export default RadioGroup;
