import React from 'react';
import {EuiFieldText} from "@elastic/eui";
import {useController, UseControllerProps} from "react-hook-form";
import {EuiFieldTextProps} from "@elastic/eui/src/components/form/field_text/field_text";

type Props<T> = UseControllerProps<T> & EuiFieldTextProps

function FieldText<T>(props: Props<T>) {
    const {field, fieldState: {invalid}} = useController(props);
    const onBlur: React.FocusEventHandler<HTMLInputElement> = (event) => {
        field.onBlur();
        props.onBlur !== undefined && props.onBlur(event);
    }
    //TODO figure this out
    //this doesn't work for some reason
    /*const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
        field.onChange();
        props.onChange !== undefined && props.onChange(event);
    }*/
    return (
        <EuiFieldText {...field} {...props as EuiFieldTextProps} isInvalid={invalid} onBlur={onBlur}/>
    );
}

export default FieldText;
