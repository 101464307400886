import {EuiCallOut} from '@elastic/eui';
import React from 'react';
import {useTranslation} from "react-i18next";


const ErrorFallback = () => {
    const {t} = useTranslation(["main"]);
    //TODO save user input!!!
    return (
        <EuiCallOut title={t("main:errorFallback.title")} color={"danger"} iconType={"alert"}>
            {t("main:errorFallback.body")}
        </EuiCallOut>
    );
};

export default ErrorFallback;
