import React from 'react';
import {EuiFormRow,} from "@elastic/eui";
import {EuiFormRowProps} from "@elastic/eui/src/components/form/form_row/form_row";
import {FieldErrors, useController, UseControllerProps} from "react-hook-form";

type Props<T> = EuiFormRowProps & UseControllerProps<T>;

const FormRow = <T, >(props: Props<T>) => {
    const {fieldState: {invalid, error}} = useController(props);
    const errors = error as FieldErrors;
    //TODO prevent control being sent as an HTML attribute
    return (
        <EuiFormRow {...props as EuiFormRowProps} isInvalid={invalid} error={errors?.message}>
            {props.children}
        </EuiFormRow>
    );
};

export default FormRow;
