import React from 'react';
import {EuiDescribedFormGroup, EuiText, EuiTitle} from "@elastic/eui";
import {useTranslation} from 'react-i18next';
import StringToId from "../../code/helpers/StringToId";

type Props = {
    children: React.ReactNode
}


const CertifierData = ({children}: Props) => {
    const {t} = useTranslation(['main']);
    return (
        <EuiDescribedFormGroup id={StringToId(t("main:certifier.title"))}
                               title={<EuiTitle size={"s"}>
                                   <h1>{t("main:certifier.title", {defaultValue: "Certifier Information"})}</h1>
                               </EuiTitle>}
                               description={
                                   <EuiText>{t("main:certifier.description", {defaultValue: "Enter the name and title of the person issuing the certificate"})}</EuiText>
                               }>
            {children}
        </EuiDescribedFormGroup>
    );
};

export default CertifierData;
