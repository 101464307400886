import React from 'react';
import {Control} from "react-hook-form";
import {
    CertificationIndicatorType,
    MethodOfQualificationType,
    OriginCriterionType,
    TableProduct
} from "../../../code/types/TableProduct";
import FieldText from "../../FormInputs/FieldText";
import FormRow from "../../FormInputs/FormRow";
import {EuiForm, EuiText} from "@elastic/eui";
import CountrySelector from "../CountrySelector";
import SuperSelect from "../../FormInputs/SuperSelect";
import {EuiSuperSelectOption} from "@elastic/eui/src/components/form/super_select/super_select_control";
import {Trans, useTranslation} from "react-i18next";

type Props = {
    control: Control<TableProduct>,
}


const ProductForm = ({control}: Props) => {
    const {t} = useTranslation(['main', "validation"]);
    const originCriteriaOptions: EuiSuperSelectOption<OriginCriterionType>[] = [
        {
            value: "A",
            inputDisplay: "A",
            dropdownDisplay:
                <React.Fragment>
                    <strong>A</strong>
                    <EuiText size="s" color="subdued">
                        <p className="euiTextColor--subdued">
                            <Trans t={t} i18nKey={"main:originCriteria.A.description"}>
                                Wholly Obtained or Produced Goods
                            </Trans>
                        </p>
                    </EuiText>
                </React.Fragment>
        }, {
            value: "B",
            inputDisplay: "B",
            dropdownDisplay:
                <React.Fragment>
                    <strong>B</strong>
                    <EuiText size="s" color="subdued">
                        <p className="euiTextColor--subdued">
                            <Trans t={t} i18nKey={"main:originCriteria.B.description"}>
                                Product‐Specific Rules of Origin
                            </Trans>
                        </p>
                    </EuiText>
                </React.Fragment>
        }, {
            value: "C",
            inputDisplay: "C",
            dropdownDisplay:
                <React.Fragment>
                    <strong>C</strong>
                    <EuiText size="s" color="subdued">
                        <p className="euiTextColor--subdued">
                            <Trans t={t} i18nKey={"main:originCriteria.C.description"}>
                                Produced entirely in the territory of one or more of the Parties exclusively from
                                originating
                                materials
                            </Trans>
                        </p>
                    </EuiText>
                </React.Fragment>
        }, {
            value: "D",
            inputDisplay: "D",
            dropdownDisplay:
                <React.Fragment>
                    <strong>D</strong>
                    <EuiText size="s" color="subdued">
                        <p className="euiTextColor--subdued">
                            <Trans t={t} i18nKey={"main:originCriteria.D.description"}>
                                "Except for a good provided for in Chapter 61 to 63 of the Harmonized System"
                            </Trans>
                        </p>
                    </EuiText>
                </React.Fragment>
        }
    ];

    const certificationIndicatorOptions: EuiSuperSelectOption<OriginCriterionType>[] = [
        {
            value: "A",
            inputDisplay: "A",
            dropdownDisplay:
                <React.Fragment>
                    <strong>A</strong>
                    <EuiText size="s" color="subdued">
                        <p className="euiTextColor--subdued">
                            <Trans t={t} i18nKey={"main:certificationIndicator.A.description"}>
                                Certifier is not the Producer, but has a certification or statement from the
                                Producer<sup>*</sup>
                            </Trans>
                        </p>
                    </EuiText>
                </React.Fragment>
        }, {
            value: "B",
            inputDisplay: "B",
            dropdownDisplay:
                <React.Fragment>
                    <strong>B</strong>
                    <EuiText size="s" color="subdued">
                        <p className="euiTextColor--subdued">
                            <Trans t={t} i18nKey={"main:certificationIndicator.B.description"}>
                                Certifier is the Importer<sup>**</sup>
                            </Trans>
                        </p>
                    </EuiText>
                </React.Fragment>
        }, {
            value: "C",
            inputDisplay: "C",
            dropdownDisplay:
                <React.Fragment>
                    <strong>C</strong>
                    <EuiText size="s" color="subdued">
                        <p className="euiTextColor--subdued">
                            <Trans t={t} i18nKey={"main:certificationIndicator.C.description"}>
                                Certifier is the Exporter
                            </Trans>
                        </p>
                    </EuiText>
                </React.Fragment>
        }, {
            value: "D",
            inputDisplay: "D",
            dropdownDisplay:
                <React.Fragment>
                    <strong>D</strong>
                    <EuiText size="s" color="subdued">
                        <p className="euiTextColor--subdued">
                            <Trans t={t} i18nKey={"main:certificationIndicator.D.description"}>
                                Certifier is the Producer
                            </Trans>
                        </p>
                    </EuiText>
                </React.Fragment>
        }
    ];

    const methodOfQualificationsOptions: EuiSuperSelectOption<MethodOfQualificationType>[] = [
        {
            value: "NC",
            inputDisplay: "NC",
            dropdownDisplay:
                <React.Fragment>
                    <strong>NC</strong>
                    <EuiText size="s" color="subdued">
                        <p className="euiTextColor--subdued">
                            <Trans t={t} i18nKey={"main:methodOfQualificationsOptions.NC.description"}>
                                Net Cost method
                            </Trans>
                        </p>
                    </EuiText>
                </React.Fragment>
        }, {
            value: "TV",
            inputDisplay: "TV",
            dropdownDisplay:
                <React.Fragment>
                    <strong>TV</strong>
                    <EuiText size="s" color="subdued">
                        <p className="euiTextColor--subdued">
                            <Trans t={t} i18nKey={"main:methodOfQualificationsOptions.TV.description"}>
                                Transactional Value method
                            </Trans>
                        </p>
                    </EuiText>
                </React.Fragment>
        }, {
            value: "TS",
            inputDisplay: "TS",
            dropdownDisplay:
                <React.Fragment>
                    <strong>TS</strong>
                    <EuiText size="s" color="subdued">
                        <p className="euiTextColor--subdued">
                            <Trans t={t} i18nKey={"main:methodOfQualificationsOptions.TS.description"}>
                                Qualified via a tariff shift
                            </Trans>
                        </p>
                    </EuiText>
                </React.Fragment>
        }, {
            value: "NO",
            inputDisplay: "NO",
            dropdownDisplay:
                <React.Fragment>
                    <strong>NO</strong>
                    <EuiText size="s" color="subdued">
                        <p className="euiTextColor--subdued">
                            <Trans t={t} i18nKey={"main:methodOfQualificationsOptions.NO.description"}>
                                If Origin Criterion A or C, select "NO"
                            </Trans>
                        </p>
                    </EuiText>
                </React.Fragment>
        }
    ]
    return (
        <EuiForm>
            <FormRow<TableProduct> name={"description"} label={t("main:table.description")} control={control}>
                <FieldText<TableProduct> name={"description"} control={control}
                                         rules={{required: t("validation:table.description")}}/>
            </FormRow>

            <FormRow<TableProduct> name={"hts"} label={t("main:table.htsNumber")} control={control}>
                <FieldText<TableProduct> name={"hts"} control={control}
                                         rules={{required: t("validation:table.htsNumber")}}/>
            </FormRow>

            <FormRow<TableProduct> name={"originCriterion"} label={t("main:table.originCriterion")} control={control}>
                <SuperSelect<TableProduct, OriginCriterionType> name={"originCriterion"} control={control}
                                                                rules={{required: t("validation:table.originCriterion")}}
                                                                options={originCriteriaOptions}/>
            </FormRow>

            <FormRow<TableProduct> name={"certificationIndicator"} label={t("main:table.certificationIndicator")}
                                   control={control}>
                <SuperSelect<TableProduct, CertificationIndicatorType> name={"certificationIndicator"}
                                                                       control={control}
                                                                       rules={{required: t("validation:table.certificationIndicator")}}
                                                                       options={certificationIndicatorOptions}/>
            </FormRow>

            <FormRow<TableProduct> name={"methodOfQualification"} label={t("main:table.methodOfQualification")}
                                   control={control}>
                <SuperSelect<TableProduct, MethodOfQualificationType> name={"methodOfQualification"}
                                                                      control={control}
                                                                      rules={{required: t("validation:table.methodOfQualification")}}
                                                                      options={methodOfQualificationsOptions}/>
            </FormRow>

            <FormRow<TableProduct> name={"countryOfOrigin"} label={t("main:table.countryOfOrigin")} control={control}>
                <CountrySelector<TableProduct> name={"countryOfOrigin"} control={control}
                                               rules={{required: t("validation:table.countryOfOrigin")}}/>
            </FormRow>

        </EuiForm>
    );
};

export default ProductForm;
