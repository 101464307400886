import React from 'react';
import {EuiDescribedFormGroup, EuiText, EuiTitle} from "@elastic/eui";
import {MainForm} from "../../code/types";
import {Control} from "react-hook-form";
import FormRow from "../FormInputs/FormRow";
import {EuiRadioGroupOption} from "@elastic/eui/src/components/form/radio/radio_group";
import RadioGroup from "../FormInputs/RadioGroup";
import {useTranslation} from 'react-i18next';
import StringToId from "../../code/helpers/StringToId";


/*const partyOptions:
    EuiSuperSelectOption<PartyTypes>[]
    = [{
    value: "importer",
    inputDisplay: <EuiHealth color={IMPORTER_COLOR}>Importer</EuiHealth>,
}, {
    value: "exporter",
    inputDisplay: <EuiHealth color={EXPORTER_COLOR}>Exporter</EuiHealth>,
}, {
    value: "producer",
    inputDisplay: <EuiHealth color={PRODUCER_COLOR}>Producer</EuiHealth>,
}
];*/

type Props = {
    control: Control<MainForm, Object>,
}


const CertParty = ({control}: Props) => {
    const { t } = useTranslation(['main']);
    const partyOptionsRadio: EuiRadioGroupOption[] = [
        {
            id: "importer",
            label: t("main:form.importer", {defaultValue: "Importer"}),
        }, {
            id: "exporter",
            label: t("main:form.exporter", {defaultValue: "Exporter"}),
        }, {
            id: "producer",
            label: t("main:form.producer", {defaultValue: "Producer"}),
        },
    ];


    return (
        <EuiDescribedFormGroup
            id={StringToId(t("main:certifyingParty.title"))}
            title={<EuiTitle size={"s"}><h1>{t("main:certifyingParty.title", {defaultValue: "Certifying Party"})}</h1>
            </EuiTitle>}
            description={
                <EuiText>{t("main:certifyingParty.description", {defaultValue: "Indicate whether the Importer, Exporter or Producer is issuing the certificate"})}</EuiText>
            }>
            <FormRow<MainForm> label={t("main:certifyingParty.prompt", {defaultValue: "Certifying Party"})} name={"partyType"} control={control}>
                <RadioGroup<MainForm> name={"partyType"} options={partyOptionsRadio} control={control}/>
            </FormRow>
        </EuiDescribedFormGroup>
    );
};

export default CertParty;
