import React, {useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {EuiFlexGroup, EuiFlexItem, EuiSuperSelect} from "@elastic/eui";
import {EuiSuperSelectOption} from "@elastic/eui/src/components/form/super_select/super_select_control";


const LanguageSelector = () => {
    const {t, i18n} = useTranslation(["main"]);
    const [lang, setLang] = useState(i18n.language.substr(0, 2));
    const options: EuiSuperSelectOption<string>[] = [{
        value: "en",
        inputDisplay: "English",
        dropdownDisplay: "English",
    },
        {
            value: "es",
            inputDisplay: "Español",
            dropdownDisplay: "Español",
        }];
    useEffect(() => {
            i18n.changeLanguage(lang);
        },
        [lang, i18n]);


    return (
        <EuiFlexGroup direction={"column"} gutterSize={"s"}>
            <EuiFlexItem>
                <b>{t("main:languageSelect.language", {defaultValue: "Language"})}</b>
            </EuiFlexItem>
            <EuiFlexItem>
                <EuiSuperSelect options={options} onChange={e => setLang(e)} valueOfSelected={lang}/>
            </EuiFlexItem>
        </EuiFlexGroup>
    );
};

export default LanguageSelector;
