import React, {useEffect, useState} from 'react';
import {EuiForm} from "@elastic/eui";
import moment from "moment";
import BlanketPeriod, {BlankedPeriodForm} from "./mainForm/BlanketPeriod";
import CertParty from "./mainForm/CertParty";
import CertifierData from "./mainForm/CertifierData";
import {FileUpload, MainForm, PartyInformation} from "../code/types";
import {useForm} from "react-hook-form";
import PartyData from "./mainForm/PartyData";
import ImporterData from "./mainForm/ImporterData";
import ExporterData from "./mainForm/ExporterData";
import ProducerData from "./mainForm/ProducerData";
import FormSpacer from "./mainForm/FormSpacer";
import SignatureDate from "./mainForm/SignatureDate";
import SignatureFile from "./mainForm/SignatureFile";
import {getFakeMainForm, getFakeProducts} from "../code/faker/fakes";
import {TableProduct} from "../code/types/TableProduct";
import ProductTableHandler from "./mainForm/ProductTableHandler";
import CertificateFlyout from "./pdfDisplay/CertificateFlyout";
import CertifierDataInput from "./mainForm/CertifierDataInput";
import PreviewInstructions from "./mainForm/PreviewInstructions";
import ErrorFallback from "./ErrorFallback";
import {ErrorBoundary} from "@sentry/react";

const emptyParty: PartyInformation = {
    name: "",
    address: "",
    city: "",
    country: undefined,
    email: "",
    phone: "",
    state: "",
    zip: "",
    taxId: ""
}

const Form = () => {

    const [blankedPeriod, setBlankedPeriod] = useState<BlankedPeriodForm>({
        startDate: moment(),
        endDate: moment().add(6, "months"),
    });
    const defaultValues = process.env.REACT_APP_FAKE_DATA === "1" ? getFakeMainForm() : {
        certifier: {name: "", title: ""},
        exporter: emptyParty,
        producer: emptyParty,
        importer: emptyParty,
        blankedPeriod: false,
        partyType: undefined
    };
    const [signingDate, setSigningDate] = useState(moment());
    const [signatureFile, setSignatureFile] = useState<FileUpload>();
    const [products, setProducts] = useState<Map<string, TableProduct>>(new Map<string, TableProduct>());
    const {control, handleSubmit, formState: {isDirty, isSubmitSuccessful}} = useForm<MainForm>({
        defaultValues: defaultValues
    });
    window.onbeforeunload = function () {
        if (isDirty && !isSubmitSuccessful)
            return "You will lose your work!";
        //if we return nothing here (just calling return;) then there will be no pop-up question at all
        return;
    };

    const [certificateFlyoutVisible, setCertificateFlyoutVisible] = useState(false);
    const [formData, setFormData] = useState<MainForm>();

    useEffect(() => {
        if (process.env.REACT_APP_FAKE_DATA === "1") {
            const fakeProducts = getFakeProducts(10);
            setProducts(new Map<string, TableProduct>(
                fakeProducts.map(value => [value.id, value])));
        }
    }, []);

    const onSubmit = (data: MainForm) => {
        setFormData(data);
        setCertificateFlyoutVisible(true);
    }
    return (
        <ErrorBoundary beforeCapture={(scope) => {
            scope.setTag("location", "form");
        }}
                       fallback={<ErrorFallback/>}
        >
            <EuiForm component={"form"} onSubmit={handleSubmit(onSubmit)}>
                <FormSpacer>
                    <CertParty control={control}/>
                    <ErrorBoundary beforeCapture={(scope) => {
                        scope.setTag("location", "blankedPeriod");
                    }}
                                   fallback={<ErrorFallback/>}
                    >
                        <BlanketPeriod
                            control={control}
                            value={blankedPeriod}
                            setBlankedPeriodData={setBlankedPeriod}
                        />
                    </ErrorBoundary>
                    <ExporterData>
                        <PartyData partyType={"exporter"} control={control}/>
                    </ExporterData>
                    <ProducerData>
                        <PartyData partyType={"producer"} control={control}/>
                    </ProducerData>
                    <ImporterData>
                        <PartyData partyType={"importer"} control={control}/>
                    </ImporterData>
                    <ErrorBoundary beforeCapture={(scope) => {
                        scope.setTag("location", "productTable");
                    }}
                                   fallback={<ErrorFallback/>}
                    >
                        <ProductTableHandler products={products} setProducts={setProducts}/>
                    </ErrorBoundary>
                    <CertifierData>
                        <CertifierDataInput control={control}/>
                    </CertifierData>
                    <SignatureDate date={signingDate} setDate={setSigningDate}/>
                    <ErrorBoundary beforeCapture={(scope) => {
                        scope.setTag("location", "signatureFile");
                    }}
                                   fallback={<ErrorFallback/>}
                    >
                        <SignatureFile file={signatureFile} setFile={setSignatureFile}/>
                    </ErrorBoundary>
                    <PreviewInstructions/>
                </FormSpacer>
                <CertificateFlyout
                    setIsVisible={setCertificateFlyoutVisible}
                    isVisible={certificateFlyoutVisible}
                    formData={formData}
                    signatureDate={signingDate}
                    signatureFile={signatureFile}
                    blankedPeriod={blankedPeriod}
                    productList={Array.from(products, ([, value]) => value)}
                />
            </EuiForm>
        </ErrorBoundary>
    );
};

export default Form;
