import React from 'react';
import {EuiBasicTable, EuiHealth,} from '@elastic/eui';
import {
    EuiTableActionsColumnType,
    EuiTableFieldDataColumnType
} from "@elastic/eui/src/components/basic_table/table_types";
import {TableProduct} from "../../code/types/TableProduct";
import {countries, CountryMapColors} from "../../code/types";
import {DefaultItemAction} from "@elastic/eui/src/components/basic_table/action_types";
import {useTranslation} from "react-i18next";


type Props = {
    products: Map<string, TableProduct>,
    //TODO create export type
    handleTableClick: (product: TableProduct, action: ("edit" | "delete")) => void,
}

const ProductTable = ({products, handleTableClick}: Props) => {
    const {t} = useTranslation(['main']);
    //const [pageIndex, setPageIndex] = useState(0);
    //const [pageSize, setPageSize] = useState(5);
    //TODO Find a more spreadsheet like approach to data input
    const actions: DefaultItemAction<TableProduct>[] = [
        {
            name: t("main:actions.delete.title", {defaultValue: "Delete"}),
            description: t("main:actions.delete.description", {defaultValue: "Delete this product"}),
            icon: "trash",
            color: "danger",
            type: "icon",
            onClick: item => {
                handleTableClick(item, "delete");
            }
        },
        {
            name: t("main:actions.delete.edit", {defaultValue: "Edit"}),
            description: t("main:actions.edit.description", {defaultValue: "Edit this product"}),
            icon: "pencil",
            color: "warning",
            type: "icon",
            onClick: item => {
                handleTableClick(item, "edit");
            }
        }
    ];

    const columnsData: EuiTableFieldDataColumnType<TableProduct>[] = [
        {
            field: "description",
            name: t("main:table.description", {defaultValue: "Description"}),
            truncateText: false, width: '30%'
        },
        {
            field: "hts",
            name: t("main:table.hts", {defaultValue: "HTS"}),
        },
        {
            field: "originCriterion",
            name: t("main:table.originCriterion", {defaultValue: "Origin Criterion"}),
        },
        {
            field: "certificationIndicator",
            name: t("main:table.certificationIndicator", {defaultValue: "Certification Indicator"}),
        },
        {
            field: "methodOfQualification",
            name: t("main:table.methodOfQualification", {defaultValue: "Method of Qualification"}),
        },
        {
            field: "countryOfOrigin",
            name: t("main:table.countryOfOrigin", {defaultValue: "Country of Origin"}),
            render: (value: string) => {
                if (!value)
                    return;
                const v = value as countries;
                const countriesS: CountryMapColors = {
                    canada: {
                        text: t("main:form.country.ca"),
                        color: "red"
                    },
                    us: {
                        text: t("main:form.country.us"),
                        color: "blue"
                    },
                    mexico: {
                        text: t("main:form.country.mx"),
                        color: "green"
                    }
                };
                const {color, text} = countriesS[v];
                return <EuiHealth color={color}>{text}</EuiHealth>
            }
        },

    ]

    const columnsAction: EuiTableActionsColumnType<TableProduct>[] = [{
        name: t("main:actions.title", {defaultValue: "Actions"}),
        actions,
    }];

    const columns = [...columnsData, ...columnsAction];
    /*
    Removed the pagination so this was a goner
    const onTableChange = (criteria: CriteriaWithPagination<TableProduct>) => {
        const {index, size} = criteria.page;
        setPageIndex(index);
        setPageSize(size);
    }

    const pagination: Pagination = {
        pageIndex,
        pageSize,
        hidePerPageOptions: products.size < 5,
        pageSizeOptions: [5, 10, 20, 50],
        totalItemCount: products.size
    }
*/
    /*const selection: EuiTableSelectionType<TableProduct> = {
        selectable: _ => true,

    };*/

    //const items = products.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);
    const items: TableProduct[] =
        Array.from(products, ([, value]) => value)
    //.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);


    //TODO add undo
    return (
        <div>
            <EuiBasicTable<TableProduct>
                items={items}
                columns={columns}
                rowHeader={"description"}
                hasActions
                itemId={item => item.id}
            />
        </div>
    );
};

export default ProductTable;
