import React from 'react';
import {EuiFlyout, EuiFlyoutBody, EuiProgress} from "@elastic/eui";
import {FileUpload, MainForm} from "../../code/types";
import {TableProduct} from "../../code/types/TableProduct";
import {Moment} from "moment";
import {BlankedPeriodForm} from "../mainForm/BlanketPeriod";
import {ErrorBoundary} from "@sentry/react";
import ErrorFallback from "../ErrorFallback";

type Props = {
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>,
    isVisible: boolean,
    formData?: MainForm,
    productList: TableProduct[],
    signatureDate: Moment,
    blankedPeriod?: BlankedPeriodForm,
    signatureFile: FileUpload | undefined,
}


const PreviewBody = React.lazy(() => import('./previewBody'));

const CertificateFlyout = ({
                               setIsVisible,
                               isVisible,
                               productList,
                               formData,
                               signatureDate,
                               blankedPeriod,
                               signatureFile
                           }: Props) => {
    if (!formData)
        return null;


    //const flyOutHeading = "CertificateFlyout";
    const closeFlyout = () => setIsVisible(false);


    const flyout =
        <EuiFlyout onClose={closeFlyout}
                   type={"overlay"}
                   size={"l"}
                   side={"right"}
        >

            <EuiFlyoutBody>
                <ErrorBoundary beforeCapture={(scope) => {
                    scope.setTag("location", "pdfGeneration");
                }}
                               fallback={<ErrorFallback/>}
                >

                    <React.Suspense fallback={<EuiProgress size="xs" color="accent"/>}>
                        <PreviewBody formData={formData} productList={productList} blankedPeriod={blankedPeriod}
                                     signatureDate={signatureDate}
                                     signatureFile={signatureFile}/>
                    </React.Suspense>
                </ErrorBoundary>
            </EuiFlyoutBody>
        </EuiFlyout>
    return (
        <React.Fragment>
            {isVisible && flyout}
        </React.Fragment>
    );
};

export default CertificateFlyout;
