import {FileUpload} from "../types";

export function CanvasMethod(file: File, jpegMode: boolean = false): Promise<FileUpload> {
    return new Promise((resolve, reject) => {
        const image = new Image();
        image.onload = () => {
            const canvas = document.createElement("canvas");
            const context2D = canvas.getContext("2d");
            if (!context2D) {
                reject("Couldn't create Canvas");
                return;
            }

            canvas.height = image.naturalHeight;
            canvas.width = image.naturalWidth;

            // Generate a jpeg with white background instead, in case of problems or idk i already wrote this
            let exportType = "image/png";
            if (jpegMode) {
                context2D.beginPath();
                context2D.fillStyle = "white";
                context2D.fillRect(0, 0, image.naturalWidth, image.naturalHeight);
                context2D.stroke();
                exportType = "image/jpeg";
            }
            //TODO scale to a max size
            context2D.drawImage(image, 0, 0);
            resolve({
                file: file,
                path: canvas.toDataURL(exportType, 1),
            });

            //clean up
            URL.revokeObjectURL(image.src);


        };
        image.src = URL.createObjectURL(file);
    });
}

export function Base64Method(file: File): Promise<FileUpload> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64 = reader.result ? reader.result.toString() : "";
            if (base64 === "") {
                reject("Empty Output")
            } else {
                resolve({
                    file: file,
                    path: base64,
                });

            }

        }
        reader.readAsDataURL(file);

    })
}
