import React, {useEffect, useState} from 'react';
import {EuiDatePicker, EuiDatePickerRange, EuiDescribedFormGroup, EuiFormRow, EuiText, EuiTitle} from "@elastic/eui";
import {Moment} from "moment";
import Switch from "../FormInputs/Switch";
import {Control, useWatch} from "react-hook-form";
import {MainForm} from "../../code/types";
import FieldText from "../FormInputs/FieldText";
import {Trans, useTranslation} from 'react-i18next';
import StringToId from "../../code/helpers/StringToId";

type Props = {
    value: BlankedPeriodForm
    setBlankedPeriodData: React.Dispatch<React.SetStateAction<BlankedPeriodForm>>,
    control: Control<MainForm, Object>,
}

function validateBlankedPeriod(startDate: Moment, endDate: Moment): boolean {
    return startDate > endDate || endDate.diff(startDate, "years", true) > 1
}

export type BlankedPeriodForm = {
    startDate: Moment,
    endDate: Moment,

}
const BlanketPeriod = ({value, setBlankedPeriodData, control}: Props) => {
    const {t} = useTranslation(['main']);
    const [startDate, setStartDate] = useState(value.startDate);
    const [endDate, setEndDate] = useState(value.endDate);
    const blanket = useWatch({control, name: "useBlanked", defaultValue: false});
    useEffect(() => {
        setBlankedPeriodData({
            startDate: startDate,
            endDate: endDate,
        });
    }, [endDate, startDate, setBlankedPeriodData,]);

    return (
        <EuiDescribedFormGroup
            id={StringToId(t("main:blankedPeriod.Title"))}
            title={<EuiTitle size={"s"}><h1>{t("main:blankedPeriod.Title", {defaultValue: "Blanked Period"})}</h1>
            </EuiTitle>}
            description={
                <EuiText>
                    <Trans t={t} i18nKey={"main:blankedPeriod.description"}>
                        <p>If your certificate will be covering multiple shipments, you should enable this option.
                            <i><b>The blanket period cannot be longer than one year</b></i>.</p>
                        <p>If only covering one shipment enter the invoice number associated with the certificate</p>
                    </Trans>
                </EuiText>
            }>
            <EuiFormRow label={t("main:blankedPeriod.switch.title", {defaultValue: "Enable Blanked Period"})}>
                <Switch<MainForm> label={t("main:blankedPeriod.switch.prompt", {defaultValue: "Use Blanked Period"})}
                                  control={control} name={"useBlanked"}/>
            </EuiFormRow>
            <EuiFormRow label={t("main:blankedPeriod.dates.title", {defaultValue: "Blanket Period Dates"})}>

                <EuiDatePickerRange
                    startDateControl={
                        <EuiDatePicker
                            disabled={!blanket}
                            selected={startDate}
                            onChange={(date) => {
                                date && setStartDate(date)
                            }}
                            startDate={startDate}
                            endDate={endDate}
                            isInvalid={validateBlankedPeriod(startDate, endDate)}
                            aria-label={t("main:blankedPeriod.dates.start", {defaultValue: "Start date"})}
                        />
                    }
                    endDateControl={
                        <EuiDatePicker
                            disabled={!blanket}
                            selected={endDate}
                            onChange={date => {
                                date && setEndDate(date)
                            }}
                            startDate={startDate}
                            endDate={endDate}
                            isInvalid={validateBlankedPeriod(startDate, endDate)}
                            aria-label={t("main:blankedPeriod.dates.end", {defaultValue: "End date"})}
                        />
                    }
                />
            </EuiFormRow>
            {startDate.diff(endDate, "years") > 1}
            <EuiFormRow label={t("main:blankedPeriod.invoice.title", {defaultValue: "Invoice Number"})}>
                <FieldText<MainForm> name={"invoiceNumber"} control={control} disabled={blanket}/>
            </EuiFormRow>
        </EuiDescribedFormGroup>
    );
};

export default BlanketPeriod;
