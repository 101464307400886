import React from 'react';
import {EuiHorizontalRule} from "@elastic/eui";

type Props = {
    children: React.ReactNodeArray
}

const FormSpacer = ({children}: Props) => {
    const elements = children.map(
        (value, index) =>
            <React.Fragment key={index}>
                {index > 0 && <EuiHorizontalRule/>}
                {value}
            </React.Fragment>);

    return (
        <React.Fragment>
            {elements}
        </React.Fragment>
    );
};

export default FormSpacer;
