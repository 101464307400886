import React from 'react';
import {EuiSpacer, EuiTitle} from "@elastic/eui";
import {useTranslation} from "react-i18next";
import StringToId from "../../code/helpers/StringToId";

type Props = {
    children: React.ReactNode
}

const ProductTableDescription = ({children}: Props) => {
    const {t} = useTranslation(['main']);
    return (
        <div id={StringToId(t("main:table.title"))}>
            <EuiTitle size={"s"}>
                <h1>{t("main:table.title", {defaultValue: "Goods Classification and Description"})}</h1>
            </EuiTitle>
            <EuiSpacer size={"m"}/>
            {children}
        </div>
    );
};

export default ProductTableDescription;
