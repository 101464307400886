import React from 'react';
import {EuiHealth, EuiSuperSelect} from "@elastic/eui";
import {EuiSuperSelectOption} from "@elastic/eui/src/components/form/super_select/super_select_control";
import {countries} from "../../code/types";
import {Controller} from "react-hook-form";
import {UseControllerProps} from "react-hook-form/dist/types/controller";
import {useTranslation} from 'react-i18next';

type Props = {
    readOnly?: boolean,
}


const CountrySelector = <T, >({name, control, readOnly, rules}: UseControllerProps<T> & Props) => {

    const {t} = useTranslation(['main']);
    const countryOptions:
        EuiSuperSelectOption<countries>[]
        = [{
        value: "mexico",
        inputDisplay: <EuiHealth color={"green"}>{t("main:form.country.mx", {defaultValue: "Mexico"})}</EuiHealth>,
    }, {
        value: "us",
        inputDisplay: <EuiHealth
            color={"blue"}>{t("main:form.country.us", {defaultValue: "United States"})}</EuiHealth>,
    }, {
        value: "canada",
        inputDisplay: <EuiHealth color={"red"}>{t("main:form.country.ca", {defaultValue: "Canada"})}</EuiHealth>,
    }
    ];


    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({field: {name, onChange, onBlur, value}}) =>
                <EuiSuperSelect name={name} onChange={onChange} onBlur={onBlur}
                                disabled={readOnly}
                                valueOfSelected={value || ""}
                                options={countryOptions}

                />
            }
        />
    );
};

export default CountrySelector;
