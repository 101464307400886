import {EuiDescribedFormGroup, EuiText, EuiTitle} from "@elastic/eui";
import React from 'react';
import {useTranslation} from "react-i18next";
import StringToId from "../../code/helpers/StringToId";

type Props = {
    children: React.ReactNode
};
const ExporterData = ({children}: Props) => {
    const {t} = useTranslation(['main']);
    return (
        <EuiDescribedFormGroup
            id={StringToId(t("main:exporter.title"))}
            title={<EuiTitle size={"s"}><h1>{t("main:exporter.title", {defaultValue: "Exporter Information"})}</h1>
            </EuiTitle>}
            description={
                <React.Fragment>
                    <EuiText>{t("main:exporter.description", {defaultValue: "Enter the exporter information"})}
                    </EuiText>
                </React.Fragment>
            }>
            {children}
        </EuiDescribedFormGroup>
    );
};

export default ExporterData;
