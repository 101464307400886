import React, {useState} from 'react';
import {EuiFlexGroup, EuiFlexItem, EuiSideNav} from "@elastic/eui";
import StringToId from "../code/helpers/StringToId";
import {useTranslation} from "react-i18next";
import LanguageSelector from "./LanguageSelector";


const SideBar = () => {
    const [isSideNavOpenOnMobile, setIsSideNavOpenOnMobile] = useState(false);
    const {t} = useTranslation(['main']);
    const formSections = [
        t("main:certifyingParty.title"),
        t("main:blankedPeriod.Title"),
        t("main:exporter.title"),
        t("main:producer.title"),
        t("main:importer.title"),
        t("main:table.title"),
        t("main:certifier.title"),
        t("main:signatureDate.title"),
        t("main:signature.title"),
        t("main:previewCertificate.title"),
    ]

    const navItems = formSections.map((value, index) => {
        const href = StringToId(value);
        return {
            href: `#${href}`,
            id: index,
            name: value
        };
    });
    const toggleOpenOnMobile = () => {
        setIsSideNavOpenOnMobile(!isSideNavOpenOnMobile);
    };
    return (
        <EuiFlexGroup direction={"column"}>
            <EuiFlexItem>
                <LanguageSelector/>
            </EuiFlexItem>
            <EuiFlexItem>
                <EuiSideNav
                    mobileTitle="Navigation"
                    toggleOpenOnMobile={toggleOpenOnMobile}
                    isOpenOnMobile={isSideNavOpenOnMobile}
                    items={[
                        {
                            name: t("main:sideBar.title"),
                            id: "root",
                            items: navItems
                        }
                    ]}
                />
            </EuiFlexItem>

        </EuiFlexGroup>
    );
};

export default SideBar;
