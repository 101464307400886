import React from 'react';
import {Moment} from "moment";
import {EuiDatePicker, EuiDescribedFormGroup, EuiFormRow, EuiText, EuiTitle} from "@elastic/eui";
import {useTranslation} from "react-i18next";
import StringToId from "../../code/helpers/StringToId";

type Props = {
    date: Moment,
    setDate: React.Dispatch<React.SetStateAction<Moment>>
}

const SignatureDate = ({date, setDate}: Props) => {
    const {t} = useTranslation(['main']);

    return (
        <EuiDescribedFormGroup
            id={StringToId(t("main:signatureDate.title"))}
            title={<EuiTitle size={"s"}><h1>{t("main:signatureDate.title", {defaultValue: "Signature Date"})}</h1>
            </EuiTitle>}
            description={
                <EuiText>
                    {t("main:signatureDate.description", {defaultValue: "Select the issuing date of the certificate"})}
                </EuiText>
            }>
            <EuiFormRow label={t("main:signatureDate.title")}>
                <EuiDatePicker
                    selected={date}
                    onChange={(date) => {
                        date && setDate(date)
                    }}
                    aria-label={t("main:signatureDate.title")}
                />
            </EuiFormRow>
        </EuiDescribedFormGroup>
    );
};

export default SignatureDate;
