import React from 'react';
import {EuiButton, EuiDescribedFormGroup, EuiText, EuiTitle} from "@elastic/eui";
import {useTranslation} from "react-i18next";
import StringToId from "../../code/helpers/StringToId";


const PreviewInstructions = () => {
    const {t} = useTranslation(['main']);
    return (
        <EuiDescribedFormGroup
            id={StringToId(t("main:previewCertificate.title"))}
            title={<EuiTitle size={"s"}>
                <h1>{t("main:previewCertificate.title", {defaultValue: "Download Certificate"})}</h1></EuiTitle>}
            description={
                <EuiText>{t("main:previewCertificate.description", {defaultValue: "You can preview and download your certificate"})}</EuiText>
            }>
            <EuiButton fill size={"m"}
                       type={"submit"}>{t("main:previewCertificate.button", {defaultValue: "Preview Certificate"})}</EuiButton>
        </EuiDescribedFormGroup>
    );
};

export default PreviewInstructions;
