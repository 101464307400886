import React, {useEffect, useState} from 'react';
import {TableProduct} from "../../code/types/TableProduct";
import FlyOutHandler from "./ProductFlyout/FlyOutHandler";
import ProductFlyout from "./ProductFlyout/ProductFlyout";
import ProductTable from "./ProductTable";
import ProductTableDescription from "./ProductTableDescription";
import {EuiSpacer, htmlIdGenerator} from "@elastic/eui";
import {useForm} from "react-hook-form";

type Props = {
    products: Map<string, TableProduct>
    setProducts: React.Dispatch<React.SetStateAction<Map<string, TableProduct>>>
}

let oldProduct: TableProduct | undefined;

const ProductTableHandler = ({products, setProducts}: Props) => {
    const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
    const formHook = useForm<TableProduct>({defaultValues: {id: "", hts: "", description: ""}});
    const {setValue, reset, getValues} = formHook;
    const [editingProduct, setEditingProduct] = useState<TableProduct>();

    useEffect(() => {
        if (!isFlyoutVisible) {
            setEditingProduct(undefined);
        }
    }, [isFlyoutVisible, setEditingProduct]);

    const handleTableClick = (product: TableProduct, action: "edit" | "delete") => {
        if (product === undefined) return;

        if (action === "edit") {
            handleOpenFlyOut(product);
        } else if (action === "delete") {
            products.delete(product.id);
            setProducts(new Map(products.entries()));
        }


    }

    const handleSend = async () => {
            setIsFlyoutVisible(false);
            const product = getValues();

            //new one we didn't edit it
            if (product.id === undefined || product.id === "") {
                product.id = htmlIdGenerator('good')();
                oldProduct = undefined;
            }
            products.set(product.id, product);
            reset();
    }
    const handleOpenFlyOut = (product: TableProduct | undefined = undefined) => {
        if (product !== undefined) {
            oldProduct = getValues();

            setEditingProduct(product);
            const keys = Object.keys(product) as unknown as (keyof TableProduct)[];
            keys.forEach(key => {
                setValue(key, product[key]);
            });
        } else {
            if (oldProduct) {
                const keys = Object.keys(oldProduct) as unknown as (keyof TableProduct)[];
                keys.forEach(key => {
                    if (oldProduct)
                        setValue(key, oldProduct[key]);
                });
            }

            //Clear for adding a new one
            //reset();
        }
        setIsFlyoutVisible(true);

    }

    return (
        <div>
            <ProductTableDescription>
                <FlyOutHandler setIsVisible={setIsFlyoutVisible} isVisible={isFlyoutVisible} onClick={handleOpenFlyOut}>
                    <ProductFlyout
                        setIsVisible={setIsFlyoutVisible}
                        isVisible={isFlyoutVisible}
                        formHook={formHook}
                        editingProduct={editingProduct}
                        handleSend={handleSend}
                    />
                </FlyOutHandler>
                <EuiSpacer size={"s"}/>
                <ProductTable products={products} handleTableClick={handleTableClick}/>
            </ProductTableDescription>
        </div>
    );
};

export default ProductTableHandler;
