import React from 'react';
import Form from "./components/Form";
import '@elastic/eui/dist/eui_theme_amsterdam_light.min.css';
import "./styles/app.css";

import {EuiPageTemplate} from '@elastic/eui';
import logo from "./assets/logo_squared512.png";
import SideBar from "./components/SideBar";
import {useTranslation} from 'react-i18next';
import "./i18n/config";
import Footer from "./components/Footer";

function App() {
    const { t } = useTranslation(['main']);
    return (
        <EuiPageTemplate
            pageHeader={{
                iconType: logo,
                pageTitle: t("main:pageTitle", {defaultValue: "USMCA Certificate of Origin"}),
                description: t("main:pageDescription", {defaultValue: "Generate a valid USMCA Certificate of Origin"}),
            }}
            pageSideBar={<SideBar/>}
        >
            <Form/>
            <Footer/>
        </EuiPageTemplate>
    );
}

export default App;
