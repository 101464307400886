import React from 'react';
import {EuiSwitch} from "@elastic/eui";
import {EuiSwitchProps} from "@elastic/eui/src/components/form/switch/switch";
import {useController, UseControllerProps} from "react-hook-form";

type Props<T> = UseControllerProps<T> & {
    label: string
};

const Switch = <T, >(props: Props<T>) => {
    const {field: {name, onBlur, onChange, value}} = useController(props);
    return (
        <EuiSwitch onBlur={onBlur}  {...props as EuiSwitchProps} name={name} onChange={event => {
            onChange(event.target.checked)
        }} checked={value}/>
    );
};

export default Switch;
