import React from 'react';
import {EuiFlexGroup, EuiFlexItem, EuiSpacer, EuiText} from "@elastic/eui";
import {useTranslation} from "react-i18next";


const Footer = () => {
    const {t} = useTranslation(["main"]);
    return (
      <>
        <EuiSpacer size={"xxl"} />
        <EuiFlexGroup alignItems={"center"}>
          <EuiFlexItem>
            <EuiText>© 2022 usmca.medinagtz.com</EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      </>
    );
};

export default Footer;
