import React from 'react';
import {
    EuiButton,
    EuiButtonEmpty,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFlyout,
    EuiFlyoutBody,
    EuiFlyoutFooter,
    EuiFlyoutHeader,
    EuiTitle
} from "@elastic/eui";
import {TableProduct} from "../../../code/types/TableProduct";
import ProductForm from "./ProductForm";
import {UseFormReturn} from "react-hook-form";
import {useTranslation} from "react-i18next";

type Props = {
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>,
    isVisible: boolean,
    editingProduct: TableProduct | undefined
    formHook: UseFormReturn<TableProduct>,
    handleSend: Function,
}

const ProductFlyout = ({setIsVisible, isVisible, formHook, handleSend, editingProduct}: Props) => {
    const {t} = useTranslation(['main']);
    const flyOutHeading = "flyOutProduct";
    const {control, handleSubmit} = formHook;
    const headerTitle = !editingProduct ? t("main:flyoutProduct.header.add") : t("main:flyoutProduct.header.edit");

    const closeFlyout = () => setIsVisible(false);

    const flyout =
        <EuiFlyout onClose={closeFlyout}
                   aria-labelledby={flyOutHeading}
                   type={"overlay"}
                   size={"s"}
        >
            <EuiFlyoutHeader hasBorder>
                <EuiTitle id={flyOutHeading}>
                    <h2>{headerTitle}</h2>
                </EuiTitle>
            </EuiFlyoutHeader>
            <EuiFlyoutBody>
                <ProductForm control={control}/>
                {/*
                <EuiSpacer/>
                <EuiFlexGroup justifyContent="spaceBetween">
                    <EuiFlexItem grow={false}>
                        <EuiButtonEmpty
                            iconType={"trash"}
                            onClick={() => {
                                reset({id: "", hts: "", description: ""});
                            }} size={"s"} color={"danger"}>
                            Clear
                        </EuiButtonEmpty>
                    </EuiFlexItem>
                </EuiFlexGroup>
                */}
            </EuiFlyoutBody>
            <EuiFlyoutFooter>
                <EuiFlexGroup justifyContent="spaceBetween">
                    <EuiFlexItem grow={false}>
                        <EuiButtonEmpty
                            iconType="cross"
                            onClick={closeFlyout}
                            flush="left"
                        >
                            {t("main:actions.cancel", {defaultValue: "Cancel"})}
                        </EuiButtonEmpty>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                        <EuiButton onClick={handleSubmit(() => {
                            handleSend()
                        })
                        } fill>
                            {t("main:actions.save", {defaultValue: "Save"})}
                        </EuiButton>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiFlyoutFooter>
        </EuiFlyout>

    return (
        <React.Fragment>
            {isVisible && flyout}
        </React.Fragment>
    );
};

export default ProductFlyout;
