import {EuiDescribedFormGroup, EuiText, EuiTitle} from "@elastic/eui";
import React from 'react';
import {useTranslation} from "react-i18next";
import StringToId from "../../code/helpers/StringToId";

type Props = {
    children: React.ReactNode
}

const ProducerData = ({children}: Props) => {
    const {t} = useTranslation(['main']);
    return (
        <EuiDescribedFormGroup
            id={StringToId(t("main:producer.title"))}
            title={<EuiTitle size={"s"}><h1>{t("main:producer.title")}</h1></EuiTitle>}
            description={
                <EuiText>{t("main:producer.description")}
                </EuiText>
            }>
            {children}
        </EuiDescribedFormGroup>
    );
};

export default ProducerData;
