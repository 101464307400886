import React from 'react';
import {EuiButton, EuiIcon} from "@elastic/eui";
import {useTranslation} from "react-i18next";

type Props = {
    setIsVisible: React.Dispatch<React.SetStateAction<boolean>>,
    isVisible?: boolean,
    children: React.ReactElement,
    onClick?: Function
}

const FlyOutHandler = ({setIsVisible, children, isVisible, onClick}: Props) => {
    const {t} = useTranslation(['main']);
    const newVal = isVisible !== true;
    return (
        <React.Fragment>
            <EuiButton fill onClick={() => {
                onClick && onClick();
                setIsVisible(newVal)
            }}>
                {t("main:actions.addGood")}<EuiIcon type={"plus"}/>
            </EuiButton>
            {children}
        </React.Fragment>
    );
};

export default FlyOutHandler;
