import React from 'react';
import FormRow from "../FormInputs/FormRow";
import {MainForm} from "../../code/types";
import FieldText from "../FormInputs/FieldText";
import {Control} from "react-hook-form";
import {useTranslation} from 'react-i18next';

type Props = {
    control: Control<MainForm, Object>,
}

const CertifierDataInput = ({control}: Props) => {
    const { t } = useTranslation(['main']);
    return (
        <React.Fragment>
            <React.Fragment>
                <FormRow<MainForm> name={`certifier.name`} label={t("main:certifier.prompt.name", {defaultValue: "Certifier Name"})} control={control}>
                    <FieldText<MainForm> name={`certifier.name`} control={control} placeholder={t("main:form.name", {defaultValue: "Name"})}/>
                </FormRow>
                <FormRow<MainForm> name={`certifier.title`} label={t("main:certifier.prompt.title", {defaultValue: "Certifier Title"})} control={control}>
                    <FieldText<MainForm> name={`certifier.title`} control={control} placeholder={t("main:form.title", {defaultValue: "Title"})}/>
                </FormRow>
            </React.Fragment>
        </React.Fragment>
    );
};

export default CertifierDataInput;
