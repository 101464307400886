import React from 'react';
import {MainForm, PartyTypesInput} from "../../code/types";
import CountrySelector from "./CountrySelector";
import {Control} from "react-hook-form";
import FieldText from "../FormInputs/FieldText";
import FormRow from "../FormInputs/FormRow";
import {useTranslation} from 'react-i18next';


//Todo make this thing support Internationalization
type Props = {
    partyType: PartyTypesInput,
    control: Control<MainForm, Object>,
}

const PartyData = ({partyType, control}: Props) => {
    const {t} = useTranslation(['main', "partyData"]);
    const readOnly = false;
    //TODO change address to textarea

    return (
        <React.Fragment>
            <FormRow<MainForm> name={`${partyType}.name`} label={t(`partyData:${partyType}.prompt.name`)} control={control}>
                <FieldText<MainForm> name={`${partyType}.name`} control={control} placeholder={t("main:form.name")}
                                     readOnly={readOnly}/>
            </FormRow>
            <FormRow<MainForm> control={control} name={`${partyType}.email`} label={t(`partyData:${partyType}.prompt.email`)}>
                <FieldText<MainForm> control={control} readOnly={readOnly} name={`${partyType}.email`}
                                     placeholder={t("main:form.email", {defaultValue: "Email"})}/>
            </FormRow>
            <FormRow<MainForm> control={control} name={`${partyType}.phone`}
                               label={t(`partyData:${partyType}.prompt.phone`)}>
                <FieldText<MainForm> control={control} readOnly={readOnly} name={`${partyType}.phone`}
                                     placeholder={t("main:form.phone", {defaultValue: "Phone"})}/>
            </FormRow>
            <FormRow<MainForm> control={control} name={`${partyType}.taxId`}
                               label={t(`partyData:${partyType}.prompt.taxId`)}
                               helpText={t("main:form.optional", {defaultValue: "Optional"})}>
                <FieldText<MainForm> control={control} readOnly={readOnly} name={`${partyType}.taxId`}
                                     placeholder={t("main:form.taxId", {defaultValue: "Tax Id"})}/>
            </FormRow>
            <FormRow<MainForm> control={control} name={`${partyType}.address`}
                               label={t(`partyData:${partyType}.prompt.address`)}>
                <FieldText<MainForm> control={control} readOnly={readOnly} name={`${partyType}.address`}
                                     placeholder={t("main:form.address", {defaultValue: "Address"})}/>
            </FormRow>

            <FormRow<MainForm> control={control} label={t(`partyData:${partyType}.prompt.zip`)}
                               name={`${partyType}.zip`}>
                <FieldText<MainForm> control={control} readOnly={readOnly} name={`${partyType}.zip`}
                                     placeholder={t("main:form.zip", {defaultValue: "Zip Code"})}/>
            </FormRow>
            <FormRow<MainForm> control={control} label={t(`partyData:${partyType}.prompt.city`)} name={`${partyType}.city`}>
                <FieldText<MainForm> control={control} readOnly={readOnly} name={`${partyType}.city`}
                                     placeholder={t("main:form.city", {defaultValue: "City"})}/>
            </FormRow>
            <FormRow<MainForm> control={control} label={t(`partyData:${partyType}.prompt.country`)} name={`${partyType}.country`}>
                <CountrySelector<MainForm> name={`${partyType}.country`} readOnly={readOnly} control={control}/>
            </FormRow>
            <FormRow<MainForm> control={control} label={t(`partyData:${partyType}.prompt.state`)} name={`${partyType}.state`}>
                <FieldText<MainForm> control={control} readOnly={readOnly} name={`${partyType}.state`}
                                     placeholder={t("main:form.state", {defaultValue: "State"})}/>
            </FormRow>
        </React.Fragment>
    );
};

export default PartyData;
