import React from 'react';
import {EuiSuperSelect} from "@elastic/eui";
import {useController, UseControllerProps} from "react-hook-form";
import {EuiSuperSelectProps} from "@elastic/eui/src/components/form/super_select/super_select";

type Props<T, U extends string> = UseControllerProps<T> & EuiSuperSelectProps<U>

const SuperSelect = <T, U extends string>(props: Props<T, U>) => {
    const {field: {name, onBlur, onChange, value}, fieldState: {invalid}} = useController<T>(props);
    return (
        <EuiSuperSelect<U>
            onBlur={onBlur}
            {...props as EuiSuperSelectProps<U>}
            name={name}
            onChange={onChange}
            valueOfSelected={value as U}
            isInvalid={invalid}
        />
    );
};

export default SuperSelect;
