import i18n from 'i18next';
import main from "./en-US/main.json";
import partyData from "./en-US/partyData.json";
import main_es_MX from "./es-MX/main.json"
import partyData_es_MX from "./es-MX/partyData.json"
import validation_en_US from "./en-US/validation.json";
import validation_es_MX from "./es-MX/validation.json";
import {initReactI18next} from 'react-i18next';

export const defaultNS = "main";
export const resources = {
    "en": {
        main,
        partyData,
        validation: validation_en_US
    },
    "es": {
        main: main_es_MX,
        partyData: partyData_es_MX,
        validation: validation_es_MX
    }
} as const;


i18n.use(initReactI18next).init({
    fallbackLng: "en",
    lng: navigator.language,
    ns: ["main", "partyData"],
    load: "all",
    interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
    },
    resources,
    defaultNS,
});
