import React from 'react';
import {EuiDescribedFormGroup, EuiFilePicker, EuiFormRow, EuiImage, EuiText, EuiTitle} from "@elastic/eui";
import {FileUpload} from "../../code/types";
import {CanvasMethod} from "../../code/helpers/ImageToBase64";
import {Trans, useTranslation} from "react-i18next";
import StringToId from "../../code/helpers/StringToId";

type Props = {
    file?: FileUpload,
    setFile: React.Dispatch<React.SetStateAction<FileUpload | undefined>>,
}

const SignatureData = ({setFile, file}: Props) => {
    const {t} = useTranslation(['main']);
    const onChange = async (files: (FileList | null)) => {
        if (files == null) return;

        if (files.length === 0) {
            setFile(undefined);
        } else {
            setFile(await CanvasMethod(files[0], true));
        }
    };


    return (
        <EuiDescribedFormGroup
            id={StringToId(t("main:signature.title"))}
            title={<EuiTitle size={"s"}>
                <h1>{t("main:signature.title", {defaultValue: "Signature"})}</h1>
            </EuiTitle>}
            description={
                <EuiText>
                    <Trans i18nKey={"main:signature.description"} t={t}>
                        Upload an <b>image</b> of your signature or leave it empty and sign by hand
                    </Trans>
                </EuiText>
            }>
            <EuiFormRow label={t("main:signature.image", {defaultValue: "Signature Image"})}>
                <EuiFilePicker
                    onChange={onChange}
                    initialPromptText={t("main:signature.imagePrompt", {defaultValue: "Select or drag and drop a file"})}
                />
            </EuiFormRow>
            {file && <EuiImage
                src={file.path}
                alt={t("main:signature.alt", {defaultValue: "Signature of the certificate"})}
                caption={file.file.name}
                allowFullScreen
            />}
        </EuiDescribedFormGroup>
    );
};

export default SignatureData;
