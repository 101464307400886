import {TableProduct} from "../types/TableProduct";
import {company, address, helpers, phone, internet, finance, name, commerce} from "faker";
import {htmlIdGenerator} from "@elastic/eui";
import {CertifierInformation, MainForm, PartyInformation} from "../types";
export function getFakeProducts(n: number = 10): TableProduct[] {
    const fakes: TableProduct[] = [];
    for (let i = 0; i < n; i++) {
        fakes[i] = {
            id: htmlIdGenerator('prefix')(),
            countryOfOrigin: helpers.randomize(["canada", "mexico", "us"]),
            methodOfQualification: helpers.randomize(["TV", "TS", "NC"]),
            hts: finance.creditCardCVV() + finance.creditCardCVV(),
            description: commerce.productName() + commerce.productName(),
            certificationIndicator: helpers.randomize(["A", "B", "C", "D"]),
            originCriterion: helpers.randomize(["A", "B", "C", "D"]),

        };
    }
    return fakes;
}

export function getFakeMainForm(): MainForm {
    return {
        partyType: helpers.randomize(["importer", "exporter", "producer"]),
        producer: getFakePartyData(),
        exporter: getFakePartyData(),
        importer: getFakePartyData(),
        certifier: getFakeCertifierData(),
        useBlanked: true,
    }

}

export function getFakePartyData(): PartyInformation {
    return {
        name: company.companyName(),
        state: address.state(),
        zip: address.zipCode(),
        country: helpers.randomize(["canada", "mexico", "us"]),
        address: address.streetAddress() + "\n" + address.secondaryAddress(),
        city: address.city(),
        phone: phone.phoneNumber(),
        email: internet.email(),
        taxId: finance.account(),
    }
}

export function getFakeCertifierData(): CertifierInformation {
    return {
        title: name.jobTitle(),
        name: `${name.firstName()} ${name.lastName()}`,
    }
}
